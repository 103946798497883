import React from 'react';
import Layout from '../components/Layout';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuTransition from '../components/MenuTransition';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center"
  },
  list: {
    paddingLeft: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
     marginTop: theme.spacing(12),
     transform: "rotate(-14.4deg)",
     paddingLeft: theme.spacing(6),
     display: "flex",
     flexDirection: "column-reverse",
    }
   },
   item: {
     padding: theme.spacing(1),
     listStyle: "none",
     textAlign: "start",
     "&:active": {
       outline: "none",
     },
     outline: "none",
     [theme.breakpoints.down('sm')]: {
       padding: theme.spacing(2),
       "&:hover": {
         color: theme.palette.primary.contrastText,
       },
     },
   },
 }));   

const InformationsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Layout pageName="Informations">
      <div className={classes.title}>
        <Typography variant="h1">Informations</Typography>
      </div>
      <ul className={classes.list}>
        <li className={classes.item}>
        <MenuTransition 
          to="/informations/mentions" 
          direction="right" 
          bgColor={theme.palette.primary.main}
          item={<Typography variant={isMobile ? "h4" : "h6"}>Mentions légales</Typography>}
        />
        </li>
        <li className={classes.item}>
          <MenuTransition 
            to="/informations/terms-and-conditions-of-sale" 
            direction="left" 
            bgColor={theme.palette.orange.main}
            item={<Typography variant={isMobile ? "h4" : "h6"}>Conditions générales de ventes</Typography>}
          />
        </li>
        <li className={classes.item}>
          <MenuTransition 
            to="/informations/transport" 
            direction="bottom" 
            bgColor={theme.palette.blue.main}
            item={<Typography variant={isMobile ? "h4" : "h6"}>Livraisons et retours</Typography>}
          />
        </li>
      </ul>
    </Layout>
  );
};
export default InformationsPage;
